<div class="mx-auto border-2 bg-white minWidthLg max-w-7xl p-4">
    <el-button class="absolute right-0" type="primary" @click="loadMore" size="mini" v-if="is_load_more"
      >Tải thêm</el-button
    >
  <div class="header relative">
    <h5>Tên người nộp thuế: {{ getSelectedCompany.tenDoanhNghiep }}</h5>
    <h5>Mã số thuế: {{ dataTableIn.MST }}</h5>
    <div class="titleTable text-center mt-2 border-2 border-black py-2">
      <p class="font-bold">
        BẢNG KÊ HÓA ĐƠN, CHỨNG TỪ HÀNG HÓA, DỊCH VỤ MUA VÀO
      </p>
    </div>
    <p class="text-center font-normal mt-1 text-sm">
      Kỳ kê khai thuế: {{ dataTableIn.period_str }}
    </p>
    <div class="text-right">
      <span>Đơn vị tiền: {{ donViTien }}</span>
    </div>
  </div>

  <div class="dataTable hscroll">
    <table class="border text-center w-full text-sm">
      <thead class="bg-yellow-400">
        <tr>
          <th class="w-12" rowspan="2">STT</th>
          <th class="w-80" colspan="4">Hóa đơn, chứng từ, biên lai nộp thuế</th>
          <th class="w-52" rowspan="2">Tên người bán</th>
          <th class="w-28" rowspan="2">Mã số thuế người bán</th>
          <th rowspan="2">Mặt hàng</th>
          <th class="w-40" rowspan="2">Doanh thu chưa có thuế GTGT</th>
          <th class="w-24" rowspan="2">Thuế suất (%)</th>
          <th class="w-40" rowspan="2">Thuế GTGT</th>
          <th class="w-24" rowspan="2">Ghi chú</th>
        </tr>

        <tr>
          <th>Mã hóa đơn</th>
          <th>Ký hiệu hóa đơn</th>
          <th>Số hóa đơn</th>
          <th>Ngày lập hóa đơn</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>[1]</td>
          <td>[2]</td>
          <td>[3]</td>
          <td>[4]</td>
          <td>[5]</td>
          <td>[6]</td>
          <td>[7]</td>
          <td>[8]</td>
          <td>[9]</td>
          <td>[10]</td>
          <td>[11]</td>
          <td>[12]</td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            1. Hàng hóa, dịch vụ dùng riêng cho SXKD chịu thuế GTGT và sử dụng
            cho các hoạt động cung cấp hàng hóa, dịch vụ không kê khai, nộp thuế
            GTGT đủ điều kiện khấu trừ thuế:
          </td>
        </tr>

        <tr v-for="(item, index) in dataTableIn.invoices" :key="index">
          <template  v-if="!item.products__tchat || item.products__tchat != 'Ghi chú, diễn giải'">
            <td>{{index +1}}</td>
            <td>{{item.form}}</td>
            <td>{{item.serial}}</td>
            <td>{{item.number}}</td>
            <td>{{ formatDate(item.issued_date)}}</td>
            <td class="text-left">{{item.seller_name}}</td>
            <td>{{item.seller_tax_code}}</td>
            <td class="text-left">
              <p class="line-clamp-6">{{item.products_name}}</p>
            </td>
            <td>
              <input-money
                v-model.lazy="item.sum_products_amount"
                class="money"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
          <td>
            {{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}
          </td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ getInvoiceStatus(item) }}</td>

          </template>
        </tr>
        <tr v-if="dataTableIn.invoices && dataTableIn.invoices.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableIn.total_revenue"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
          <td>
            <input-money
              v-model.lazy="dataTableIn.vat_output"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            2. Hàng hóa, dịch vụ dùng chung cho SXKD chịu thuế và không chịu
            thuế đủ điều kiện khấu trừ thuế:
          </td>
        </tr>

        <tr
          v-for="(item, index) in dataTableIn.invoices_revenue_10"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td>{{item.seller_name}}</td>
          <td>{{item.seller_tax_code}}</td>
          <td>
            <input-money
              v-model.lazy="item.amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableIn.revenue_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
          <td>
            <input-money
              v-model.lazy="dataTableIn.vat_amount_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            3. Hàng hóa, dịch vụ dùng cho dự án đầu tư đủ điều kiện được khấu
            trừ thuế:
          </td>
        </tr>

        <tr
          v-for="(item, index) in dataTableIn.invoices_revenue_0"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td>{{item.seller_name}}</td>
          <td>{{item.seller_tax_code}}</td>
          <td>
            <input-money
              v-model.lazy="item.amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableIn.revenue_0"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <div class="footTable">
      <div class="mt-5 flex items-center text-sm">
        <h5 class="w-1/2">
          Tổng giá trị HHDV mua vào phục vụ SXKD được khấu trừ thuế GTGT:
        </h5>
        <div class="border-black border-2 w-1/2 py-1 px-2 text-right 2xl:mr-56">
          <input-money
            v-model.lazy="dataTableIn.total_revenue"
            class="money"
            :invoiceStyle="true"
            :table="true"
            :decima="0"
          />
        </div>
      </div>
      <div class="mt-5 flex items-center text-sm">
        <h5 class="w-1/2">
          Tổng thuế GTGT của HHDV mua vào đủ điều kiện được khấu trừ:
        </h5>
        <div class="border-black border-2 w-1/2 py-1 px-2 text-right 2xl:mr-56">
          <input-money
            v-model.lazy="dataTableIn.vat_output"
            class="money"
            :invoiceStyle="true"
            :table="true"
            :decima="0"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="sign flex justify-end mt-10 mb-52 text-sm font-bold">
    <div class="text-center">
      <div class="date text-right">
        ...............,ngày..........tháng..........năm..........
      </div>
      <h5 class="mt-3">
        <span class="uppercase">Người nộp thuế </span>hoặc <br /><span
          class="uppercase"
        >
          ĐẠI DIỆN HỢP PHÁP CỦA NGƯỜI NỘP THUẾ
        </span>
        <br />
        <span>Ký tên, đóng dấu (ghi rõ họ tên và chức vụ)</span>
      </h5>
    </div>
  </div>
</div>
