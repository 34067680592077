import { mapGetters } from "vuex";
import { URL } from "@/api/report.js";
import InputMoney from "@/components/inputMoney.vue";
import formatDate from "@/plugins/formatDate.js";
import { convertTaxNumberToText, getInvoiceStatus } from "@/plugins/common.js";

export default {
  name: "bangKeMuaVao",
  components: {
    InputMoney,
  },
  data() {
    return {
      dataTableIn: {
        invoices: []
      },
      donViTien: "Việt Nam đồng",
      is_load_more: false,
      idInterval: null
    };
  },
  created() {
    this.activeTabs = this.routeName;
    this.getDetailReport();
  },

  methods: {
    async getDetailReport(isLoadMore=false, showLoading=true) {
      if (showLoading) {
        this.$store.dispatch("setIsReportLoading", true);
      }
      const params = {};
      params.company_id = this.getSelectedCompany.id;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;
      params.report_period = this.getReportPeriod;
      params.is_load_more = isLoadMore ? 1 : 0;

      if (
        this.getSelectedCompany.id &&
        this.getReportPeriod &&
        this.getReportYear
      ) {
        const result = await this.$request({
          url: URL.REPORT,
          params,
        });
        const res = result.data;
        if (res.code == 200) {
          this.dataTableIn = res.data.data;
          this.is_load_more = res.data.is_load_more;
          this.$store.dispatch("setIsDownloadable", res.data.is_downloadable);
          this.$store.dispatch("setIsDownloadProcessing", res.data.is_download_processing);
          this.$store.dispatch("setIsLargePDF", res.data.is_large_pdf);
        }
      }
      this.$store.dispatch("setIsReportLoading", false);
    },
    handleUpdateDownnloadProcess(isProcessing) {
      clearInterval(this.idInterval);
      if (isProcessing) {
        this.idInterval = setInterval(() => {
          this.getDetailReport(false, false);
        }, 5000);
      }
    },
    loadMore() {
      this.$swal({
        title: "Tải toàn bộ dữ liệu hóa đơn",
        html: "<i>Hành động này có thể gây ảnh hưởng tới trải nghiệm sử dụng web</i>",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.getDetailReport(true);
        }
      });
    },
    formatDate,
    convertTaxNumberToText,
    getInvoiceStatus,
  },

  computed: {
    ...mapGetters([
      "getSelectedCompany",
      "getReportPeriod",
      "getReportYear",
      "getIsDownloadProcessing",
    ]),

    routeName() {
      return this.$route.name;
    },

    changeCompanyOrYearOrdeclaration() {
      if (
        this.getSelectedCompany.id &&
        this.getReportPeriod &&
        this.getReportYear
      ) {
        return `${this.getSelectedCompany.id}-${this.getReportPeriod}-${this.getReportYear}`;
      }
    },
  },

  watch: {
    changeCompanyOrYearOrdeclaration() {
      this.getDetailReport();
    },
    getIsDownloadProcessing(newVal) {
      this.handleUpdateDownnloadProcess(newVal);
    }
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
